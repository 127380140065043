<template>
	<section class="group">
		<div class="header d-flex justify-content-between border br-5 pl-4 mb-3">
			<p class="f-14 my-1">{{ name }}</p>
			<div class="icon-option" @click="$emit('drop', index)">
				<span class="f-14">{{ label }}</span>
				<i 
				class="f-20 cr-pointer ml-2" 
				:class="dropped ? 'icon-arrow-up-drop-circle' : 'icon-arrow-down-drop-circle'"
				/>
			</div>
		</div>
		<div v-if="dropped" class="collapse d-flex flex-column gap-2 py-3" :class="{ 'dropped': dropped } ">
			<div v-for="(config, index ) in configurations" :key="index" 
				@click="verConfiguracion(config)">
			<etapa 
				:id="config.id"
				:name="config.nombre"
				:price="config.valor"
				:users="config.horas_hombre"
				/>
			</div>
		</div>
	</section>
</template>

<script>
import etapa from './etapa.vue'
export default {
	components: { etapa },
	props: {
		index:{
			type: Number
		},
		name: {
			type: String,
			default: '',
		},
		label: {
			type: String,
			default: '0/0'
		},
		configurations: {
			type: Array,
			default: () => []
		},
		dropped: {
			type: Boolean,
		}
	},
	methods: {
		verConfiguracion(configuracion) {
			this.$router.push({'name': 'ventas.presencia-equipos.detalle.configuracion', params: { id_configuracion: configuracion.id, tabla:configuracion.tabla}})
		}
	},
}
</script>

<style lang="scss" scoped>
.group{
	.header{
		background-color: #F9F9F9;
	}
}

.collapse{
	opacity: 0;
	height: 0;
	&.dropped{
		opacity: 1;
		height: calc(100% - 1px);
		transition: opacity .3s ease-in-out;
	}
}

</style>