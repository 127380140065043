<template>
	<div class="row">
		<div class="col-6 pl-5">
			<p class="f-14 cr-pointer w-fit">{{ name }}</p>
		</div>
		<div class="col-6 d-flex gap-3">
			<p>
				<i class="icon-cash-multiple f-22"></i>
				<span class="f-14">{{ formatoMoneda(price) }}</span>
			</p>
			<p>
				<i class="icon-account-clock f-22"></i>
				<span class="f-14"> {{ users }} </span>
			</p>
		</div>
	</div>
</template>

<script>
export default {
	props:{
		id: {
			type: Number,
			require: true,
		},
		name: {
			type: String,
			default: ''
		},
		price: {
			type: Number,
			default: 0
		},
		users: {
			type: Number,
			default: 0
		}
	}
}
</script>

<style scoped lang="scss">
.w-fit{
	width: fit-content;
}
</style>