<template>
	<section class="equipo">
		<navbar-admin icon="icon-dolar"/>

		<div class="row mt-4 mx-4">
			<div class="col-10 d-flex flex-column">
				<div class="d-flex mb-4 ml-3">
					<img class="img-equipo border br-5 obj-cover" :src="detalleEquipo.foto_producto | helper-storage" :alt="detalleEquipo.nombre">
					<div class="ml-3 d-flex flex-column gap-3">
						<p class="f-18 f-800">{{ detalleEquipo.nombre }}</p>
						<div class="f-14">
							<div class="d-flex justify-content-between align-items-center mb-2">
								<p>
									<span class="f-600">Precio: </span>
									<span> {{ formatoMoneda(detalleEquipo.precio) }} </span>
									<i class="text-orange icon-alert f-20"></i>
								</p>
								<p>
									<span class="f-600">Etapas: </span>
									<span> {{ detalleEquipo.cantidad_etapas }}</span>
								</p>
								<p>
									<span class="f-600">Materiales</span>
									<span> {{ detalleEquipo.materiales }}</span>
								</p>
								<p>
									<span class="f-600">Horas hombre</span>
									<span> {{ detalleEquipo.horas_hombre }}</span>
								</p>
							</div>
							<p>
								{{detalleEquipo.comentario}}
							</p>
						</div>
						<div class="d-flex justify-content-between f-14">
							<p>
								<span class="f-600">Producto: </span>
								<span class="mr-3"> {{ detalleEquipo.nombre }}</span>
							</p>
							<p>
								<span class="f-600">Categoría: </span>
								<span class="mr-3"> {{ detalleEquipo.categoria }}</span>
							</p>
							<p>
								<span class="f-600">Subcategoría</span>
								<span> {{ detalleEquipo.Subcategoria }}</span>
							</p>
						</div>
					</div>
				</div>
				<div class="d-middle justify-content-between gap-2 border br-5 company-card f-14">
					<p>
						<img class="rounded-circle obj-cover" :src="detalleEquipo.foto_cliente | helper-storage" :alt="detalleEquipo.cliente" >
						<span class="ml-2">{{ detalleEquipo.cliente }}</span>
					</p>
					<p>
						<span class="f-600">Fecha de venta: </span>
						<span>{{ detalleEquipo.fecha_venta }}</span>
					</p>
				</div>
			</div>
		</div>
		<el-divider class="ml-3"/>
		<div class="row mt-4 mx-4">
			<div class="col-9 mb-3">
				<div class="d-flex align-items-center justify-content-between mb-3">
					<p class="f-14">Etapas del equipo</p>
					<div>
						<el-input v-model="query" placeholder="Buscar etapa" />
					</div>
				</div>
				<div class="d-flex flex-column">
					<etapa-grupo 
					v-for="(etapa, index) in detalleEquipo.etapas" 
					:key="index"
					:index="index"
					:name="etapa.nombre"
					:label="`${index + 1}/${detalleEquipo.cantidad_etapas}`"
					:configurations="etapa.configuraciones"
					:tabla="tabla"
					:dropped="droppedList.includes(index)"
					@drop="droppeFun"
					/>
				</div>
			</div>
		</div>
	</section>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import EtapaGrupo from './components/etapaGrupo.vue'
export default {
	components: { EtapaGrupo },
	data(){
		return {
			tablaEquipo: this.$route.params.tabla,
			query: '',
			droppedList: [],
			equipo: {
				id: 1,
				name: 'Oscarrrrrrrrrrrr ',
				image: `funcionarios/10490026385f2b11c1d54a56.778327760.873646001596658113.png`,
				price: 140000,
				stages: 5,
				materials: 84,
				hours: 540,
				product: 'Camara de secado spray',
				category: 'Equipos personalizados',
				subcategory: 'A medida',
				saleDate: '22 mar. 2020',
				companyName: 'Productos Naturales de la Sabana S.A.S',
				companyImage: `funcionarios/10490026385f2b11c1d54a56.778327760.873646001596658113.png`,
				etapas: [
					{
						name: 'Efecto 1',
						start: 1,
						end: 2,
						dropped: false,
						configurations: [
							{
								id: 1,
								name: 'Configuracion 1',
								price: 150000,
								users: 150
							},
							{
								id: 2,
								name: 'Planta láctea de 23.000 litros',
								price: 250000,
								users: 254
							},
						]
					},
					{
						name: 'Termocompresor',
						start: 0,
						end: 1,
						dropped: false,
						configurations: [
							{
								id: 3,
								name: 'Instalación transversal',
								price: 150000,
								users: 150
							},
						]
					},
					{
						name: 'Distribuidor',
						start: 3,
						end: 10,
						dropped: false,
						configurations: []
					},
				]
			},
		}
	},
	async created(){
		// if (!this.tabla) {
		// 	this.$router.push({ name: 'ventas.presencia-equipos.detalle', params: { id: this.$route.params.id }})
		// 	return
		// }
		await this.listarDetalle(),
		this.getBreadcumbs(['ventas.main']);
		this.spliceBreadcumbs([
			{
				position: 2,
				breadcumb: {
					name: this.detalleEquipo.nombre,
					route: 'ventas.presencia-equipos.detalle',
					params: {
						id: this.detalleEquipo.id
					}
				}
			}
		])
	},
	computed: {
		...mapGetters({
            detalleEquipo: 'ventas/presencia_equipos/detalleEquipo',
            tabla: 'ventas/presencia_equipos/tabla',
        }),
	},
	methods: {
		...mapActions({
			getBreadcumbs: 'navigation/breadcumbs/getBreadcumbs',
			spliceBreadcumbs: 'navigation/breadcumbs/spliceBreadcumbs',
            Action_get_detalle_equipo_vendido: 'ventas/presencia_equipos/Action_get_detalle_equipo_vendido',
		}),
		...mapMutations({
            set_tabla: 'ventas/presencia_equipos/set_tabla'
        }),
		droppeFun(index){
			if (this.droppedList.includes(index)) {
				this.droppedList = this.droppedList.filter(el => el != index)
			}else {
				this.droppedList.push(index)
			}
		},
		async listarDetalle(){
			const payload = {
				id:this.idConfiguration = this.$route.params.id,
				tabla: !this.tablaEquipo ? this.tabla : this.tablaEquipo
			}
			await this.Action_get_detalle_equipo_vendido(payload)
		}
	}
}
</script>

<style scoped lang="scss">
.img-equipo{
	width: 160px;
	height: 160px;
}

.company-card{
	background-color: #F9F9F9;
	padding: 9px 18px;
	img{
		width: 35px;
		height: 35px;
	}
}
</style>